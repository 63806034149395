import React from "react";
import { Layout } from "../../components/Layout";
import { Seo } from "../../components/Seo";
import { getContactUrl, getCookieUrl, getPrivacyUrl } from "../../utils/urls";
import { Link } from "../../components/Link";
import { Container } from "../../components/Section";

const PrivacyContent: React.FC = () => (
  <>
    <h1 id="header">PRIVACY NOTICE</h1>
    <p>
      <strong>Last updated September 25, 2023</strong>
    </p>
    <p>
      This privacy notice for LeanyLabs OÜ ("<strong>Company</strong>," "
      <strong>we</strong>," "<strong>us</strong>," or "<strong>our</strong>"),
      describes how and why we might collect, store, use, and/or share ("
      <strong>process</strong>") your information when you use our services ("
      <strong>Services</strong>"), such as when you:
    </p>
    <ul className="list">
      <li>
        Visit our website at{" "}
        <a href="https://leanylabs.com/">https://leanylabs.com</a>, or any
        website of ours that links to this privacy notice
      </li>
      <li>
        Install and use our monday.com apps ("Search in Updates", "Google Sheets
        Integration", "Google Docs Integration", "DocX Templater", "Org Chart"),
        or any other application of ours that links to this privacy notice
      </li>
      <li>
        Engage with us in other related ways, including any sales, marketing, or
        events
      </li>
    </ul>
    <p>
      <strong>Questions or concerns? </strong>Reading this privacy notice will
      help you understand your privacy rights and choices. If you do not agree
      with our policies and practices, please do not use our Services. If you
      still have any questions or concerns, please contact us at
      privacy@leanylabs.com.
    </p>
    <h2>SUMMARY OF KEY POINTS</h2>
    <p>
      <strong>
        <em>
          This summary provides key points from our privacy notice, but you can
          find out more details about any of these topics by clicking the link
          following each key point or by using our table of contents below to
          find the section you are looking for. You can also{" "}
          <a href="#table-of-contents">click here</a> to go directly to our
          table of contents.
        </em>
      </strong>
    </p>
    <p>
      <strong>What personal information do we process?</strong> When you visit,
      use, or navigate our Services, we may process personal information
      depending on how you interact with LeanyLabs OÜ and the Services, the
      choices you make, and the products and features you use.{" "}
      <a href="#info-collect">Click here</a> to learn more.
    </p>
    <p>
      <strong>Do we process any sensitive personal information?</strong> We do
      not process sensitive personal information.
    </p>
    <p>
      <strong>Do we receive any information from third parties?</strong> We may
      receive information from public databases, marketing partners, social
      media platforms, and other outside sources.{" "}
      <a href="#info-collect-other">Click here</a> to learn more.
    </p>
    <p>
      <strong>How do we process your information?</strong> We process your
      information to provide, improve, and administer our Services, communicate
      with you, for security and fraud prevention, and to comply with law. We
      may also process your information for other purposes with your consent. We
      process your information only when we have a valid legal reason to do so.{" "}
      <a href="#info-process">Click here</a> to learn more.
    </p>
    <p>
      <strong>
        In what situations and with which types of parties do we share personal
        information?
      </strong>{" "}
      We may share information in specific situations and with specific
      categories of third parties. <a href="#info-share">Click here</a> to learn
      more.
    </p>
    <p>
      <strong>How do we keep your information safe?</strong> We have
      organizational and technical processes and procedures in place to protect
      your personal information. However, no electronic transmission over the
      internet or information storage technology can be guaranteed to be 100%
      secure, so we cannot promise or guarantee that hackers, cybercriminals, or
      other unauthorized third parties will not be able to defeat our security
      and improperly collect, access, steal, or modify your information.{" "}
      <a href="#info-safe">Click here</a> to learn more.
    </p>
    <p>
      <strong>What are your rights?</strong> Depending on where you are located
      geographically, the applicable privacy law may mean you have certain
      rights regarding your personal information.{" "}
      <a href="#privacy-rights">Click here</a> to learn more.
    </p>
    <p>
      <strong>How do you exercise your rights?</strong> The easiest way to
      exercise your rights is by filling out our{" "}
      <Link to={getContactUrl()}>contact form</Link>, or by contacting us. We
      will consider and act upon any request in accordance with applicable data
      protection laws.
    </p>
    <p>
      Want to learn more about what LeanyLabs OÜ does with any information we
      collect? <a href="#header">Click here</a> to review the notice in full.
    </p>
    <h2 id="table-of-contents">TABLE OF CONTENTS</h2>
    <ol className="list">
      <li>
        <a href="#info-collect">WHAT INFORMATION DO WE COLLECT?</a>
      </li>
      <li>
        <a href="#info-process">HOW DO WE PROCESS YOUR INFORMATION?</a>
      </li>
      <li>
        <a href="#legal-bases">
          WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?
        </a>
      </li>
      <li>
        <a href="#info-share">
          WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
        </a>
      </li>
      <li>
        <a href="#cookies">
          DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
        </a>
      </li>
      <li>
        <a href="#info-transfer">
          IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
        </a>
      </li>
      <li>
        <a href="#info-keep">HOW LONG DO WE KEEP YOUR INFORMATION?</a>
      </li>
      <li>
        <a href="#info-safe">HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
      </li>
      <li>
        <a href="#info-minors">DO WE COLLECT INFORMATION FROM MINORS?</a>
      </li>
      <li>
        <a href="#privacy-rights">WHAT ARE YOUR PRIVACY RIGHTS?</a>
      </li>
      <li>
        <a href="#do-not-track">CONTROLS FOR DO-NOT-TRACK FEATURES</a>
      </li>
      <li>
        <a href="#california">
          DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </a>
      </li>
      <li>
        <a href="#updates">DO WE MAKE UPDATES TO THIS NOTICE?</a>
      </li>
      <li>
        <a href="#how-to-contact">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
      </li>
      <li>
        <a href="#your-data">
          HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
        </a>
      </li>
    </ol>
    <h2 id="info-collect">1. WHAT INFORMATION DO WE COLLECT?</h2>
    <h3>Personal information you disclose to us</h3>
    <p>
      <strong>
        <em>
          In Short: We collect personal information that you provide to us.
        </em>
      </strong>
    </p>
    <p>
      We collect personal information that you voluntarily provide to us when
      you express an interest in obtaining information about us or our products
      and Services, when you participate in activities on the Services, or
      otherwise when you contact us.
    </p>
    <p>
      <strong>Personal Information Provided by You.</strong> The personal
      information that we collect depends on the context of your interactions
      with us and the Services, the choices you make, and the products and
      features you use. The personal information we collect may include the
      following:
    </p>
    <ul className="list">
      <li>app usage</li>

      <li>data collected from surveys</li>

      <li>cv and other job application data such as background checks</li>

      <li>names</li>

      <li>email addresses</li>
    </ul>
    <p>
      <strong>Sensitive Information.</strong> We do not process sensitive
      information.
    </p>
    <p>
      All personal information that you provide to us must be true, complete,
      and accurate, and you must notify us of any changes to such personal
      information.
    </p>
    <h3>Information automatically collected</h3>
    <p>
      <strong>
        <em>
          In Short: Some information — such as your Internet Protocol (IP)
          address and/or browser and device characteristics — is collected
          automatically when you visit our Services.
        </em>
      </strong>
    </p>
    <p>
      We automatically collect certain information when you visit, use, or
      navigate the Services. This information does not reveal your specific
      identity (like your name or contact information) but may include device
      and usage information, such as your IP address, browser and device
      characteristics, operating system, language preferences, referring URLs,
      device name, country, location, information about how and when you use our
      Services, and other technical information. This information is primarily
      needed to maintain the security and operation of our Services, and for our
      internal analytics and reporting purposes.
    </p>
    <p>
      Like many businesses, we also collect information through cookies and
      similar technologies. You can find out more about this in our Cookie
      Notice:{" "}
      <Link to={getCookieUrl()}>https://leanylabs.com/legal/cookie/</Link>.
    </p>
    <p>The information we collect includes:</p>
    <ul className="list">
      <li>
        <em>Log and Usage Data.</em> Log and usage data is service-related,
        diagnostic, usage, and performance information our servers automatically
        collect when you access or use our Services and which we record in log
        files. Depending on how you interact with us, this log data may include
        your IP address, device information, browser type, and settings and
        information about your activity in the Services (such as the date/time
        stamps associated with your usage, pages and files viewed, searches, and
        other actions you take such as which features you use), device event
        information (such as system activity, error reports (sometimes called
        "crash dumps"), and hardware settings).
      </li>

      <li>
        <em>Device Data.</em> We collect device data such as information about
        your computer, phone, tablet, or other device you use to access the
        Services. Depending on the device used, this device data may include
        information such as your IP address (or proxy server), device and
        application identification numbers, location, browser type, hardware
        model, Internet service provider and/or mobile carrier, operating
        system, and system configuration information.
      </li>

      <li>
        <em>Location Data.</em> We collect location data such as information
        about your device's location, which can be either precise or imprecise.
        How much information we collect depends on the type and settings of the
        device you use to access the Services. For example, we may use GPS and
        other technologies to collect geolocation data that tells us your
        current location (based on your IP address). You can opt out of allowing
        us to collect this information either by refusing access to the
        information or by disabling your Location setting on your device.
        However, if you choose to opt out, you may not be able to use certain
        aspects of the Services.
      </li>
    </ul>
    <h3 id="info-collect-other">Information collected from other sources</h3>
    <p>
      <strong>
        <em>
          In Short: We may collect limited data from public databases, marketing
          partners, and other outside sources.
        </em>
      </strong>
    </p>
    <p>
      In order to enhance our ability to provide relevant marketing, offers, and
      services to you and update our records, we may obtain information about
      you from other sources, such as public databases, joint marketing
      partners, affiliate programs, data providers, and from other third
      parties. This information includes mailing addresses, job titles, email
      addresses, phone numbers, intent data (or user behavior data), Internet
      Protocol (IP) addresses, social media profiles, social media URLs, and
      custom profiles, for purposes of targeted advertising and event promotion.
    </p>
    <h2 id="info-process">2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
    <p>
      <strong>
        <em>
          In Short: We process your information to provide, improve, and
          administer our Services, communicate with you, for security and fraud
          prevention, and to comply with law. We may also process your
          information for other purposes with your consent.
        </em>
      </strong>
    </p>
    <p>
      <strong>
        We process your personal information for a variety of reasons, depending
        on how you interact with our Services, including:
      </strong>
    </p>
    <ul className="list">
      <li>
        <strong>
          To deliver and facilitate delivery of services to the user.{" "}
        </strong>
        We may process your information to provide you with the requested
        service.
      </li>

      <li>
        <strong>To respond to user inquiries/offer support to users. </strong>We
        may process your information to respond to your inquiries and solve any
        potential issues you might have with the requested service.
      </li>

      <li>
        <strong>To send administrative information to you. </strong>We may
        process your information to send you details about our products and
        services, changes to our terms and policies, and other similar
        information.
      </li>

      <li>
        <strong>To fulfill and manage your orders.</strong> We may process your
        information to fulfill and manage your orders, payments, returns, and
        exchanges made through the Services.
      </li>

      <li>
        <strong>To request feedback. </strong>We may process your information
        when necessary to request feedback and to contact you about your use of
        our Services.
      </li>

      <li>
        <strong>To send you marketing and promotional communications. </strong>
        We may process the personal information you send to us for our marketing
        purposes, if this is in accordance with your marketing preferences. You
        can opt out of our marketing emails at any time. For more information,
        see "WHAT ARE YOUR PRIVACY RIGHTS?" below).
      </li>

      <li>
        <strong>To deliver targeted advertising to you.</strong> We may process
        your information to develop and display personalized content and
        advertising tailored to your interests, location, and more. For more
        information see our Cookie Notice:{" "}
        <Link to={getCookieUrl()}>https://leanylabs.com/legal/cookie/</Link>.
      </li>

      <li>
        <strong>To protect our Services.</strong> We may process your
        information as part of our efforts to keep our Services safe and secure,
        including fraud monitoring and prevention.
      </li>

      <li>
        <strong>To identify usage trends.</strong> We may process information
        about how you use our Services to better understand how they are being
        used so we can improve them.
      </li>

      <li>
        <strong>
          To determine the effectiveness of our marketing and promotional
          campaigns.
        </strong>{" "}
        We may process your information to better understand how to provide
        marketing and promotional campaigns that are most relevant to you.
      </li>

      <li>
        <strong>To save or protect an individual's vital interest.</strong> We
        may process your information when necessary to save or protect an
        individual’s vital interest, such as to prevent harm.
      </li>
    </ul>
    <h2 id="legal-bases">
      3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
    </h2>
    <p>
      <strong>
        <em>
          In Short: We only process your personal information when we believe it
          is necessary and we have a valid legal reason (i.e., legal basis) to
          do so under applicable law, like with your consent, to comply with
          laws, to provide you with services to enter into or fulfill our
          contractual obligations, to protect your rights, or to fulfill our
          legitimate business interests.
        </em>
      </strong>
    </p>
    <h3>
      <em>
        <u>If you are located in the EU or UK, this section applies to you.</u>
      </em>
    </h3>
    <p>
      The General Data Protection Regulation (GDPR) and UK GDPR require us to
      explain the valid legal bases we rely on in order to process your personal
      information. As such, we may rely on the following legal bases to process
      your personal information:
    </p>
    <ul className="list">
      <li>
        <strong>Consent. </strong>We may process your information if you have
        given us permission (i.e., consent) to use your personal information for
        a specific purpose. You can withdraw your consent at any time.{" "}
        <a href="#withdrawing-your-consent">Click here</a> to learn more.
      </li>

      <li>
        <strong>Performance of a Contract.</strong> We may process your personal
        information when we believe it is necessary to fulfill our contractual
        obligations to you, including providing our Services or at your request
        prior to entering into a contract with you.
      </li>

      <li>
        <strong>Legitimate Interests.</strong> We may process your information
        when we believe it is reasonably necessary to achieve our legitimate
        business interests and those interests do not outweigh your interests
        and fundamental rights and freedoms. For example, we may process your
        personal information for some of the purposes described in order to:
      </li>

      <li>
        Send users information about special offers and discounts on our
        products and services
      </li>

      <li>
        Develop and display personalized and relevant advertising content for
        our users
      </li>

      <li>
        Analyze how our services are used so we can improve them to engage and
        retain users
      </li>

      <li>Support our marketing activities</li>

      <li>Diagnose problems and/or prevent fraudulent activities</li>

      <li>
        Understand how our users use our products and services so we can improve
        user experience
      </li>

      <li>
        <strong>Legal Obligations.</strong> We may process your information
        where we believe it is necessary for compliance with our legal
        obligations, such as to cooperate with a law enforcement body or
        regulatory agency, exercise or defend our legal rights, or disclose your
        information as evidence in litigation in which we are involved.
      </li>

      <li>
        <strong>Vital Interests.</strong> We may process your information where
        we believe it is necessary to protect your vital interests or the vital
        interests of a third party, such as situations involving potential
        threats to the safety of any person.
      </li>
    </ul>
    <h3>
      <em>
        <u>If you are located in Canada, this section applies to you.</u>
      </em>
    </h3>
    <p>
      We may process your information if you have given us specific permission
      (i.e., express consent) to use your personal information for a specific
      purpose, or in situations where your permission can be inferred (i.e.,
      implied consent). You can withdraw your consent at any time.{" "}
      <a href="#withdrawing-your-consent">Click here</a> to learn more.
    </p>
    <p>
      In some exceptional cases, we may be legally permitted under applicable
      law to process your information without your consent, including, for
      example:
    </p>
    <ul className="list">
      <li>
        If collection is clearly in the interests of an individual and consent
        cannot be obtained in a timely way
      </li>

      <li>For investigations and fraud detection and prevention</li>

      <li>For business transactions provided certain conditions are met</li>

      <li>
        If it is contained in a witness statement and the collection is
        necessary to assess, process, or settle an insurance claim
      </li>

      <li>
        For identifying injured, ill, or deceased persons and communicating with
        next of kin
      </li>

      <li>
        If we have reasonable grounds to believe an individual has been, is, or
        may be victim of financial abuse
      </li>

      <li>
        If it is reasonable to expect collection and use with consent would
        compromise the availability or the accuracy of the information and the
        collection is reasonable for purposes related to investigating a breach
        of an agreement or a contravention of the laws of Canada or a province
      </li>

      <li>
        If disclosure is required to comply with a subpoena, warrant, court
        order, or rules of the court relating to the production of records
      </li>

      <li>
        If it was produced by an individual in the course of their employment,
        business, or profession and the collection is consistent with the
        purposes for which the information was produced
      </li>

      <li>
        If the collection is solely for journalistic, artistic, or literary
        purposes
      </li>

      <li>
        If the information is publicly available and is specified by the
        regulations
      </li>
    </ul>
    <h2 id="info-share">
      4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
    </h2>
    <p>
      <strong>
        <em>
          In Short: We may share information in specific situations described in
          this section and/or with the following categories of third parties.
        </em>
      </strong>
    </p>
    <p>
      <strong>
        Vendors, Consultants, and Other Third-Party Service Providers.
      </strong>{" "}
      We may share your data with third-party vendors, service providers,
      contractors, or agents ("<strong>third parties</strong>") who perform
      services for us or on our behalf and require access to such information to
      do that work. We have contracts in place with our third parties, which are
      designed to help safeguard your personal information. This means that they
      cannot do anything with your personal information unless we have
      instructed them to do it. They will also not share your personal
      information with any organization apart from us. They also commit to
      protect the data they hold on our behalf and to retain it for the period
      we instruct. The categories of third parties we may share personal
      information with are as follows:
    </p>
    <ul className="list">
      <li>Cloud Computing Services</li>

      <li>Communication &amp; Collaboration Tools</li>

      <li>Data Analytics Services</li>

      <li>Finance &amp; Accounting Tools</li>

      <li>Order Fulfillment Service Providers</li>

      <li>Sales &amp; Marketing Tools</li>

      <li>Website Hosting Service Providers</li>

      <li>Performance Monitoring Tools</li>

      <li>Data Storage Service Providers</li>

      <li>Recruiting Tools</li>

      <li>Payment Processors</li>

      <li>Error Monitoring and Alerting Tools</li>
    </ul>
    <p>
      We also may need to share your personal information in the following
      situations:
    </p>
    <ul className="list">
      <li>
        <strong>Business Transfers.</strong> We may share or transfer your
        information in connection with, or during negotiations of, any merger,
        sale of company assets, financing, or acquisition of all or a portion of
        our business to another company.
      </li>

      <li>
        <strong>Affiliates. </strong>We may share your information with our
        affiliates, in which case we will require those affiliates to honor this
        privacy notice. Affiliates include our parent company and any
        subsidiaries, joint venture partners, or other companies that we control
        or that are under common control with us.
      </li>

      <li>
        <strong>Business Partners.</strong> We may share your information with
        our business partners to offer you certain products, services, or
        promotions.
      </li>
    </ul>
    <h2 id="cookies">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
    <p>
      <strong>
        <em>
          In Short: We may use cookies and other tracking technologies to
          collect and store your information.
        </em>
      </strong>
    </p>
    <p>
      We may use cookies and similar tracking technologies (like web beacons and
      pixels) to access or store information. Specific information about how we
      use such technologies and how you can refuse certain cookies is set out in
      our Cookie Notice:{" "}
      <Link to={getCookieUrl()}>https://leanylabs.com/legal/cookie/</Link>.
    </p>
    <h2 id="info-transfer">
      6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
    </h2>
    <p>
      <strong>
        <em>
          In Short: We may transfer, store, and process your information in
          countries other than your own.
        </em>
      </strong>
    </p>
    <p>
      Our servers are located in. If you are accessing our Services from
      outside, please be aware that your information may be transferred to,
      stored, and processed by us in our facilities and by those third parties
      with whom we may share your personal information (see "WHEN AND WITH WHOM
      DO WE SHARE YOUR PERSONAL INFORMATION?" above), in Ukraine, and other
      countries.
    </p>
    <p>
      If you are a resident in the European Economic Area (EEA) or United
      Kingdom (UK), then these countries may not necessarily have data
      protection laws or other similar laws as comprehensive as those in your
      country. However, we will take all necessary measures to protect your
      personal information in accordance with this privacy notice and applicable
      law.
    </p>
    <p>European Commission's Standard Contractual Clauses:</p>
    <p>
      We have implemented measures to protect your personal information,
      including by using the European Commission's Standard Contractual Clauses
      for transfers of personal information between our group companies and
      between us and our third-party providers. These clauses require all
      recipients to protect all personal information that they process
      originating from the EEA or UK in accordance with European data protection
      laws and regulations. Our Standard Contractual Clauses can be provided
      upon request. We have implemented similar appropriate safeguards with our
      third-party service providers and partners and further details can be
      provided upon request.
    </p>
    <h2 id="info-keep">7. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
    <p>
      <strong>
        <em>
          In Short: We keep your information for as long as necessary to fulfill
          the purposes outlined in this privacy notice unless otherwise required
          by law.
        </em>
      </strong>
    </p>
    <p>
      We will only keep your personal information for as long as it is necessary
      for the purposes set out in this privacy notice, unless a longer retention
      period is required or permitted by law (such as tax, accounting, or other
      legal requirements). No purpose in this notice will require us keeping
      your personal information for longer than 2 years.
    </p>
    <p>
      When we have no ongoing legitimate business need to process your personal
      information, we will either delete or anonymize such information, or, if
      this is not possible (for example, because your personal information has
      been stored in backup archives), then we will securely store your personal
      information and isolate it from any further processing until deletion is
      possible.
    </p>
    <h2 id="info-safe">8. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
    <p>
      <strong>
        <em>
          In Short: We aim to protect your personal information through a system
          of organizational and technical security measures.
        </em>
      </strong>
    </p>
    <p>
      We have implemented appropriate and reasonable technical and
      organizational security measures designed to protect the security of any
      personal information we process. However, despite our safeguards and
      efforts to secure your information, no electronic transmission over the
      Internet or information storage technology can be guaranteed to be 100%
      secure, so we cannot promise or guarantee that hackers, cybercriminals, or
      other unauthorized third parties will not be able to defeat our security
      and improperly collect, access, steal, or modify your information.
      Although we will do our best to protect your personal information,
      transmission of personal information to and from our Services is at your
      own risk. You should only access the Services within a secure environment.
    </p>
    <h2 id="info-minors">9. DO WE COLLECT INFORMATION FROM MINORS?</h2>
    <p>
      <strong>
        <em>
          In Short: We do not knowingly collect data from or market to children
          under 18 years of age.
        </em>
      </strong>
    </p>
    <p>
      We do not knowingly solicit data from or market to children under 18 years
      of age. By using the Services, you represent that you are at least 18 or
      that you are the parent or guardian of such a minor and consent to such
      minor dependent’s use of the Services. If we learn that personal
      information from users less than 18 years of age has been collected, we
      will deactivate the account and take reasonable measures to promptly
      delete such data from our records. If you become aware of any data we may
      have collected from children under age 18, please contact us at
      privacy@leanylabs.com.
    </p>
    <h2 id="privacy-rights">10. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
    <p>
      <strong>
        <em>
          In Short: In some regions, such as the European Economic Area (EEA),
          United Kingdom (UK), and Canada, you have rights that allow you
          greater access to and control over your personal information. You may
          review, change, or terminate your account at any time.
        </em>
      </strong>
    </p>
    <p>
      In some regions (like the EEA, UK, and Canada), you have certain rights
      under applicable data protection laws. These may include the right (i) to
      request access and obtain a copy of your personal information, (ii) to
      request rectification or erasure; (iii) to restrict the processing of your
      personal information; and (iv) if applicable, to data portability. In
      certain circumstances, you may also have the right to object to the
      processing of your personal information. You can make such a request by
      contacting us by using the contact details provided in the section "HOW
      CAN YOU CONTACT US ABOUT THIS NOTICE?" below.
    </p>
    <p>
      We will consider and act upon any request in accordance with applicable
      data protection laws.
    </p>
    <p></p>
    <p>
      If you are located in the EEA or UK and you believe we are unlawfully
      processing your personal information, you also have the right to complain
      to your local data protection supervisory authority. You can find their
      contact details here:{" "}
      <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
        https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
      </a>
      .
    </p>
    <p>
      If you are located in Switzerland, the contact details for the data
      protection authorities are available here:{" "}
      <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">
        https://www.edoeb.admin.ch/edoeb/en/home.html
      </a>
      .
    </p>
    <p>
      <strong id="withdrawing-your-consent">
        <u>Withdrawing your consent:</u>
      </strong>{" "}
      If we are relying on your consent to process your personal information,
      which may be express and/or implied consent depending on the applicable
      law, you have the right to withdraw your consent at any time. You can
      withdraw your consent at any time by contacting us by using the contact
      details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS
      NOTICE?" below.
    </p>
    <p>
      However, please note that this will not affect the lawfulness of the
      processing before its withdrawal, nor when applicable law allows, will it
      affect the processing of your personal information conducted in reliance
      on lawful processing grounds other than consent.
    </p>
    <p>
      <strong>
        <u>Opting out of marketing and promotional communications: </u>
      </strong>
      You can unsubscribe from our marketing and promotional communications at
      any time by clicking on the unsubscribe link in the emails that we send,
      replying "STOP" or "UNSUBSCRIBE" to the SMS messages that we send, or by
      contacting us using the details provided in the section "HOW CAN YOU
      CONTACT US ABOUT THIS NOTICE?" below. You will then be removed from the
      marketing lists. However, we may still communicate with you — for example,
      to send you service-related messages that are necessary for the
      administration and use of your account, to respond to service requests, or
      for other non-marketing purposes.
    </p>
    <p>
      <strong>
        <u>Cookies and similar technologies:</u>
      </strong>{" "}
      Most Web browsers are set to accept cookies by default. If you prefer, you
      can usually choose to set your browser to remove cookies and to reject
      cookies. If you choose to remove cookies or reject cookies, this could
      affect certain features or services of our Services. To opt out of
      interest-based advertising by advertisers on our Services visit{" "}
      <a href="http://www.aboutads.info/choices/">
        http://www.aboutads.info/choices/
      </a>
      . For further information, please see our Cookie Notice:{" "}
      <Link to={getCookieUrl()}>https://leanylabs.com/legal/cookie/</Link>.
    </p>
    <p>
      If you have questions or comments about your privacy rights, you may email
      us at privacy@leanylabs.com.
    </p>
    <h2 id="do-not-track">11. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
    <p>
      Most web browsers and some mobile operating systems and mobile
      applications include a Do-Not-Track ("DNT") feature or setting you can
      activate to signal your privacy preference not to have data about your
      online browsing activities monitored and collected. At this stage no
      uniform technology standard for recognizing and implementing DNT signals
      has been finalized. As such, we do not currently respond to DNT browser
      signals or any other mechanism that automatically communicates your choice
      not to be tracked online. If a standard for online tracking is adopted
      that we must follow in the future, we will inform you about that practice
      in a revised version of this privacy notice.
    </p>
    <h2 id="california">
      12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
    </h2>
    <p>
      <strong>
        <em>
          In Short: Yes, if you are a resident of California, you are granted
          specific rights regarding access to your personal information.
        </em>
      </strong>
    </p>
    <p>
      California Civil Code Section 1798.83, also known as the "Shine The Light"
      law, permits our users who are California residents to request and obtain
      from us, once a year and free of charge, information about categories of
      personal information (if any) we disclosed to third parties for direct
      marketing purposes and the names and addresses of all third parties with
      which we shared personal information in the immediately preceding calendar
      year. If you are a California resident and would like to make such a
      request, please submit your request in writing to us using the contact
      information provided below.
    </p>
    <p>
      If you are under 18 years of age, reside in California, and have a
      registered account with Services, you have the right to request removal of
      unwanted data that you publicly post on the Services. To request removal
      of such data, please contact us using the contact information provided
      below and include the email address associated with your account and a
      statement that you reside in California. We will make sure the data is not
      publicly displayed on the Services, but please be aware that the data may
      not be completely or comprehensively removed from all our systems (e.g.,
      backups, etc.).
    </p>
    <h3>CCPA Privacy Notice</h3>
    <p>The California Code of Regulations defines a "resident" as:</p>
    <p>
      (1) every individual who is in the State of California for other than a
      temporary or transitory purpose and
    </p>
    <p>
      (2) every individual who is domiciled in the State of California who is
      outside the State of California for a temporary or transitory purpose
    </p>
    <p>All other individuals are defined as "non-residents."</p>
    <p>
      If this definition of "resident" applies to you, we must adhere to certain
      rights and obligations regarding your personal information.
    </p>
    <h3>What categories of personal information do we collect?</h3>
    <p>
      We have collected the following categories of personal information in the
      past twelve (12) months:
    </p>

    <table>
      <tr>
        <td>
          <strong>Category</strong>
        </td>
        <td>
          <strong>Examples</strong>
        </td>
        <td>
          <strong>Collected</strong>
        </td>
      </tr>
      <tr>
        <td>A. Identifiers</td>
        <td>
          Contact details, such as real name, alias, postal address, telephone
          or mobile contact number, unique personal identifier, online
          identifier, Internet Protocol address, email address, and account name
        </td>
        <td>YES</td>
      </tr>
      <tr>
        <td>
          B. Personal information categories listed in the California Customer
          Records statute
        </td>
        <td>
          Name, contact information, education, employment, employment history,
          and financial information
        </td>
        <td>YES</td>
      </tr>
      <tr>
        <td>
          C. Protected classification characteristics under California or
          federal law
        </td>
        <td>Gender and date of birth</td>
        <td>NO</td>
      </tr>
      <tr>
        <td>D. Commercial information</td>
        <td>
          Transaction information, purchase history, financial details, and
          payment information
        </td>
        <td>YES</td>
      </tr>
      <tr>
        <td>E. Biometric information</td>
        <td>Fingerprints and voiceprints</td>
        <td>NO</td>
      </tr>
      <tr>
        <td>F. Internet or other similar network activity</td>
        <td>
          Browsing history, search history, online behavior, interest data, and
          interactions with our and other websites, applications, systems, and
          advertisements
        </td>
        <td>YES</td>
      </tr>
      <tr>
        <td>G. Geolocation data</td>
        <td>Device location</td>
        <td>NO</td>
      </tr>
      <tr>
        <td>
          H. Audio, electronic, visual, thermal, olfactory, or similar
          information
        </td>
        <td>
          Images and audio, video or call recordings created in connection with
          our business activities
        </td>
        <td>NO</td>
      </tr>
      <tr>
        <td>I. Professional or employment-related information</td>
        <td>
          Business contact details in order to provide you our services at a
          business level or job title, work history, and professional
          qualifications if you apply for a job with us
        </td>
        <td>YES</td>
      </tr>
      <tr>
        <td>J. Education Information</td>
        <td>Student records and directory information</td>
        <td>NO</td>
      </tr>
      <tr>
        <td>K. Inferences drawn from other personal information</td>
        <td>
          Inferences drawn from any of the collected personal information listed
          above to create a profile or summary about, for example, an
          individual’s preferences and characteristics
        </td>
        <td>YES</td>
      </tr>
    </table>

    <p>
      We may also collect other personal information outside of these categories
      instances where you interact with us in person, online, or by phone or
      mail in the context of:
    </p>
    <ul className="list">
      <li>Receiving help through our customer support channels;</li>

      <li>Participation in customer surveys or contests; and</li>

      <li>
        Facilitation in the delivery of our Services and to respond to your
        inquiries.
      </li>
    </ul>
    <h3>How do we use and share your personal information?</h3>
    <p>LeanyLabs OÜ collects and shares your personal information through:</p>
    <ul className="list">
      <li>Targeting cookies/Marketing cookies</li>

      <li>Social media cookies</li>

      <li>Beacons/Pixels/Tags</li>

      <li>Click redirects and other events: Google Analytics</li>

      <li>Errors in the applications: Rollbar</li>
    </ul>
    <p>
      More information about our data collection and sharing practices can be
      found in this privacy notice and our Cookie Notice:{" "}
      <Link to={getCookieUrl()}>https://leanylabs.com/legal/cookie/</Link>.
    </p>
    <p>
      You may contact us by email at privacy@leanylabs.com, by{" "}
      <Link to={getContactUrl()}>using the form</Link>, or by referring to the
      contact details at the bottom of this document.
    </p>
    <p>
      If you are using an authorized agent to exercise your right to opt out we
      may deny a request if the authorized agent does not submit proof that they
      have been validly authorized to act on your behalf.
    </p>
    <h3>Will your information be shared with anyone else?</h3>
    <p>
      We may disclose your personal information with our service providers
      pursuant to a written contract between us and each service provider. Each
      service provider is a for-profit entity that processes the information on
      our behalf.
    </p>
    <p>
      We may use your personal information for our own business purposes, such
      as for undertaking internal research for technological development and
      demonstration. This is not considered to be "selling" of your personal
      information.
    </p>
    <p>
      LeanyLabs OÜ has not sold any personal information to third parties for a
      business or commercial purpose in the preceding twelve (12) months.
      LeanyLabs OÜ has disclosed the following categories of personal
      information to third parties for a business or commercial purpose in the
      preceding twelve (12) months:
    </p>
    <ul className="list">
      <li>
        Category A. Identifiers, such as contact details like your real name,
        alias, postal address, telephone or mobile contact number, unique
        personal identifier, online identifier, Internet Protocol address, email
        address, and account name.
      </li>

      <li>
        Category B. Personal information, as defined in the California Customer
        Records law, such as your name, contact information, education,
        employment, employment history, and financial information.
      </li>

      <li>
        Category D. Commercial information, such as transaction information,
        purchase history, financial details, and payment information.
      </li>

      <li>
        Category F. Internet or other electronic network activity information,
        such as browsing history, search history, online behavior, interest
        data, and interactions with our and other websites, applications,
        systems, and advertisements.
      </li>

      <li>
        Category I. Professional or employment-related information, such as
        business contact details in order to provide you our services at a
        business level or job title, work history, and professional
        qualifications if you apply for a job with us.
      </li>

      <li>
        Category K. Inferences drawn from any of the personal information listed
        above to create a profile or summary about, for example, an individual's
        preferences and characteristics.
      </li>
    </ul>
    <p>
      The categories of third parties to whom we disclosed personal information
      for a business or commercial purpose can be found under "WHEN AND WITH
      WHOM DO WE SHARE YOUR PERSONAL INFORMATION?".
    </p>
    <h3>Your rights with respect to your personal data</h3>
    <p>
      <u>Right to request deletion of the data — Request to delete</u>
    </p>
    <p>
      You can ask for the deletion of your personal information. If you ask us
      to delete your personal information, we will respect your request and
      delete your personal information, subject to certain exceptions provided
      by law, such as (but not limited to) the exercise by another consumer of
      his or her right to free speech, our compliance requirements resulting
      from a legal obligation, or any processing that may be required to protect
      against illegal activities.
    </p>
    <p>
      <u>Right to be informed — Request to know</u>
    </p>
    <p>Depending on the circumstances, you have a right to know:</p>
    <ul className="list">
      <li>whether we collect and use your personal information;</li>

      <li>the categories of personal information that we collect;</li>

      <li>
        the purposes for which the collected personal information is used;
      </li>

      <li>whether we sell your personal information to third parties;</li>

      <li>
        the categories of personal information that we sold or disclosed for a
        business purpose;
      </li>

      <li>
        the categories of third parties to whom the personal information was
        sold or disclosed for a business purpose; and
      </li>

      <li>
        the business or commercial purpose for collecting or selling personal
        information.
      </li>
    </ul>
    <p>
      In accordance with applicable law, we are not obligated to provide or
      delete consumer information that is de-identified in response to a
      consumer request or to re-identify individual data to verify a consumer
      request.
    </p>
    <p>
      <u>
        Right to Non-Discrimination for the Exercise of a Consumer’s Privacy
        Rights
      </u>
    </p>
    <p>
      We will not discriminate against you if you exercise your privacy rights.
    </p>
    <p>
      <u>Verification process</u>
    </p>
    <p>
      Upon receiving your request, we will need to verify your identity to
      determine you are the same person about whom we have the information in
      our system. These verification efforts require us to ask you to provide
      information so that we can match it with information you have previously
      provided us. For instance, depending on the type of request you submit, we
      may ask you to provide certain information so that we can match the
      information you provide with the information we already have on file, or
      we may contact you through a communication method (e.g., phone or email)
      that you have previously provided to us. We may also use other
      verification methods as the circumstances dictate.
    </p>
    <p>
      We will only use personal information provided in your request to verify
      your identity or authority to make the request. To the extent possible, we
      will avoid requesting additional information from you for the purposes of
      verification. However, if we cannot verify your identity from the
      information already maintained by us, we may request that you provide
      additional information for the purposes of verifying your identity and for
      security or fraud-prevention purposes. We will delete such additionally
      provided information as soon as we finish verifying you.
    </p>
    <p>
      <u>Other privacy rights</u>
    </p>
    <ul className="list">
      <li>You may object to the processing of your personal information.</li>

      <li>
        You may request correction of your personal data if it is incorrect or
        no longer relevant, or ask to restrict the processing of the
        information.
      </li>

      <li>
        You can designate an authorized agent to make a request under the CCPA
        on your behalf. We may deny a request from an authorized agent that does
        not submit proof that they have been validly authorized to act on your
        behalf in accordance with the CCPA.
      </li>

      <li>
        You may request to opt out from future selling of your personal
        information to third parties. Upon receiving an opt-out request, we will
        act upon the request as soon as feasibly possible, but no later than
        fifteen (15) days from the date of the request submission.
      </li>
    </ul>
    <p>
      To exercise these rights, you can contact us by email at
      privacy@leanylabs.com, by <Link to={getContactUrl()}>using the form</Link>
      , or by referring to the contact details at the bottom of this document.
      If you have a complaint about how we handle your data, we would like to
      hear from you.
    </p>
    <h2 id="updates">13. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
    <p>
      <strong>
        <em>
          In Short: Yes, we will update this notice as necessary to stay
          compliant with relevant laws.
        </em>
      </strong>
    </p>
    <p>
      We may update this privacy notice from time to time. The updated version
      will be indicated by an updated "Revised" date and the updated version
      will be effective as soon as it is accessible. If we make material changes
      to this privacy notice, we may notify you either by prominently posting a
      notice of such changes or by directly sending you a notification. We
      encourage you to review this privacy notice frequently to be informed of
      how we are protecting your information.
    </p>
    <h2 id="how-to-contact">14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
    <p>
      If you have questions or comments about this notice, you may email us at
      privacy@leanylabs.com or by post to:
    </p>
    <p>
      LeanyLabs OÜ
      <br />
      Sepapaja 6<br />
      Tallinn, Harju maakond 15551
      <br />
      Estonia
      <br />
    </p>
    <h2 id="your-data">
      15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
    </h2>
    <p>
      Based on the applicable laws of your country, you may have the right to
      request access to the personal information we collect from you, change
      that information, or delete it. To request to review, update, or delete
      your personal information, please{" "}
      <Link to={getContactUrl()}>contact us</Link>.
    </p>
  </>
);

const Page: React.FC = () => (
  <Layout>
    <Seo
      ogFullTitle
      title="Privacy Notice"
      description="Please read our privacy notice. Your privacy is important to us. Feel free to contact us with any questions."
    />
    <Container>
      <PrivacyContent />
    </Container>
  </Layout>
);

export default Page;
